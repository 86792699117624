<template>
  <div class="container d-flex flex-column">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2" v-if="showRemembered">
              Welcome back, {{ firstName }}
            </h1>
            <h1 class="h2" v-else>Welcome back</h1>
            <p class="lead">Sign in to your account to continue</p>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="m-sm-4">
                <div class="text-center">
                  <avatar
                    :username="firstName"
                    :src="avatar"
                    :size="132"
                    class="avatar mb-3 mx-auto"
                    v-if="showRemembered"
                  ></avatar>
                </div>
                <b-alert
                  :show="alert.visible"
                  fade
                  dismissible
                  @dismissed="hideAlert()"
                  :variant="alert.type"
                >
                  <div class="alert-message">{{ alert.msg }}</div>
                </b-alert>
                <form @submit.prevent="login">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      class="form-control form-control-lg"
                      type="email"
                      name="email"
                      placeholder="e.g. john.smith@gmail.com"
                      v-model="form.email"
                    />
                  </div>
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      class="form-control form-control-lg"
                      type="password"
                      name="password"
                      placeholder="e.g. hunter2"
                      v-model="form.password"
                    />
                    <small>
                      <b-link
                        :to="{ name: 'login', params: { page: 'reset' } }"
                      >
                        Forgot Password?
                      </b-link>
                    </small>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="form.remember"
                      name="remember-me"
                      :value="true"
                      :unchecked-value="false"
                      class="text-small"
                    >
                      Remember me next time
                    </b-form-checkbox>
                  </div>
                  <div class="text-center mt-3">
                    <b-button
                      class="login-button mb-2"
                      type="submit"
                      variant="primary"
                      size="lg"
                    >
                      Sign in
                    </b-button>
                    <b-button
                      class="login-button btn-google mb-2"
                      size="lg"
                      @click="googleLogin()"
                    >
                      <i class="fab fa-google"></i> Sign in with Google
                    </b-button>
                    <b-button
                      class="login-button btn-github"
                      size="lg"
                      @click="githubLogin()"
                    >
                      <i class="fab fa-github"></i> Sign in with Github
                    </b-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import firebase from "firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import Users from "../firebase/users";
import Avatar from "vue-avatar";

export default {
  name: "Login",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    Avatar,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        remember: false,
      },
      alert: {
        type: "",
        msg: "",
        visible: false,
      },
      showRemembered: false,
      name: "",
      firstName: "",
      avatar: "",
    };
  },
  methods: {
    ...mapMutations(["setUser", "setSearchKey"]),
    login() {
      if (this.form.email === "") {
        this.displayAlert("Please enter your email.", "error");
        return false;
      }
      if (this.form.password === "") {
        this.displayAlert("Please enter your password.", "error");
        return false;
      }
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((user) => {
          console.log(user);
          this.displayAlert("Login successful.", "success");
          this.setUser(user.user);
          this.navigate("dashboard");
          let u = new Users();
          u.onLogin().then((resp) => {
            this.setSearchKey(resp.data.searchKey);
          });
          // Save remember me info
          if (this.form.remember) {
            this.getObjectSnapshot("users/" + user.user.uid + "/profile").then(
              (profile) => {
                const remember = {
                  email: user.user.email,
                  firstName: profile.firstName,
                  lastName: profile.lastName,
                  avatar: profile.avatar,
                };
                localStorage.setItem("remember-me", JSON.stringify(remember));
              }
            );
          } else {
            localStorage.removeItem("remember-me");
          }
        })
        .catch((err) => {
          this.displayAlert("Login failed.", "error");
          console.log(err.message);
        });
    },
    googleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
      firebase.auth().signInWithRedirect(provider);
    },
    githubLogin() {
      const provider = new firebase.auth.GithubAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    },
    sendReset() {
      if (this.form.email === "") {
        this.displayAlert("Please enter your email.", "error");
        return false;
      }
      firebase
        .auth()
        .sendPasswordResetEmail(this.form.email)
        .then(() => {
          this.displayAlert(
            "Password reset processed. Please check your email.",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.displayAlert(
            "An error occurred while processing the request. Please try again later.",
            "error"
          );
        });
    },
    displayAlert(message, type = null) {
      this.alert.type =
        type === "error" ? "danger" : type === null ? "info" : type;
      this.alert.msg = message;
      this.alert.visible = true;
    },
    hideAlert() {
      this.alert.visible = false;
    },
  },
  created() {
    // Handle OAuth redirect
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        console.log(result);
        if (result.credential) {
          const user = {
            uid: result.user.uid,
            name: result.user.displayName,
            email: result.user.email,
          };
          this.setUser(user);
          let u = new Users();
          u.onLogin().then((resp) => {
            this.setSearchKey(resp.data.searchKey);
          });
          if (result.additionalUserInfo.isNewUser) {
            this.navigate("provider-signup");
          } else {
            this.navigate("dashboard");
          }
        }
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        console.log(error);
        if (errorCode === "auth/account-exists-with-different-credential") {
          this.displayAlert(
            "Login failed: Account exists with different credentials.",
            "error"
          );
        } else {
          this.displayAlert("Login failed.", "error");
        }
      });
    // Handle remmber me
    let remember = localStorage.getItem("remember-me");
    if (remember) {
      // Attempt to parse JSON
      try {
        remember = JSON.parse(remember);
        this.name = remember.firstName + " " + remember.lastName;
        this.firstName = remember.firstName;
        this.avatar = remember.avatar;
        this.form.email = remember.email;
        this.form.remember = true;
        this.showRemembered = true;
      } catch (e) {
        console.log(e);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.login-button {
  width: 100%;
  max-width: 250px;
}
</style>
